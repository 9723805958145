import { BlockModel } from "../../../Models/BlockModel";
import { OperatorAvatar } from "../../../Models/OperatorModel";
import {
  BlockServices,
  OperatorServices,
  PendencyServices,
} from "../../../Services/Interfaces";
import { SYNC_DELAY } from "../../../Utils/Utils";
import { CategorizationStateArgs } from "./Model";

export const getBlockToSolve = async (
  clientId: string,
  setBlockToSolve: React.Dispatch<
    React.SetStateAction<BlockModel | undefined | null>
  >
) => setBlockToSolve(await BlockServices.getBlockToSolve(clientId));

export const categorizeSync = (
  client: CategorizationStateArgs,
  setUncategorizedBlocks: React.Dispatch<React.SetStateAction<number>>,
  setOperators: React.Dispatch<React.SetStateAction<OperatorAvatar[]>>
) => {
  const interval = setInterval(async () => {
    const result = await OperatorServices.blockSync(client.id);
    setUncategorizedBlocks(result.uncategorizedCount);
    setOperators(result.operators);
  }, SYNC_DELAY);

  const closeInteraval = () => {
    clearInterval(interval);
    OperatorServices.blockUnsync();
  };
  return closeInteraval;
};

export const createPendency = PendencyServices.createPendency;
