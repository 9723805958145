import { cnpj, cpf } from "cpf-cnpj-validator";
import { TextEntryWithErrorState } from "./Model";

export class Validations {
  static loginError = "Usuário ou senha incorretos";
  static loginBlockedError = "Seu acesso foi bloqueado";
  static passwordError = "A senha deve ter no mínimo 6 caracteres";
  static emailError = "Este e-mail não é válido";
  static emptyError = "Este campo não pode ficar vazio";
  static cpfError = "CPF inválido";
  static cnpjError = "CNPJ inválido";
  static cepError = "CEP inválido";
  static phoneError = "Telefone inválido";
}

export const emailValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  let regexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  console.log("email text", state.current.state.text?.current);
  const validEmail = regexp.test(state.current.state.text?.current ?? "");
  if (!validEmail) {
    state.current.setState({ error: Validations.emailError });
    return Validations.emailError;
  }
  return undefined;
};

export const passwordValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  if ((state.current.state.text?.current.length ?? 0) < 6) {
    state.current.setState({ error: Validations.passwordError });
    return Validations.passwordError;
  }
  return undefined;
};

export const emptyValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  if ((state.current.state.text?.current.length ?? 0) === 0) {
    state.current.setState({ error: Validations.emptyError });
    return Validations.emptyError;
  }
  return undefined;
};

export const cpfValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  if (!cpf.isValid(state.current.state.text?.current ?? "")) {
    state.current.setState({ error: Validations.cpfError });
    return Validations.cpfError;
  }
  return undefined;
};

export const cnpjValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  if (!cnpj.isValid(state.current.state.text?.current ?? "")) {
    state.current.setState({ error: Validations.cnpjError });
    return Validations.cnpjError;
  }
  return undefined;
};

export const cepValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  if (state.current.state.text?.current.length !== 9) {
    state.current.setState({ error: Validations.cepError });
    return Validations.cepError;
  }
  return undefined;
};

export const phoneValidation = (
  state: React.MutableRefObject<TextEntryWithErrorState>
) => {
  if (state.current.state.text?.current.length !== 16) {
    state.current.setState({ error: Validations.phoneError });
    return Validations.phoneError;
  }
  return undefined;
};
