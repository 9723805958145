import { BlockModel } from "../../Models/BlockModel";
import { AccountingInfos } from "../../Pages/Accounting/Accounting/Model";
import { SelectedDocumentModel } from "../../Pages/Client/BlockCreate/Model";
import { HomeInfos } from "../../Pages/Client/Home/Model";
import { callPromiseWrapper } from "../../Utils/Login";
import { IBlockServices } from "../Interfaces";

class BlockServicesImpl implements IBlockServices {
  async getClientBlocks(clientId?: string) {
    const result = (
      await callPromiseWrapper(
        "/api/block/list",
        clientId ? { clientId: clientId } : undefined
      )
    ).data as HomeInfos | AccountingInfos;
    result.blocks.forEach((block) => {
      block.date = new Date(block.date);
      block.date.setDate(block.date.getDate() + 1)
      block.value = block.value.toString();
      block.viewed = clientId ? true : block.viewed;
      if (block.contabilizedDatetime) {
        block.contabilizedDatetime = new Date(block.contabilizedDatetime);
      } else {
        block.contabilizedDatetime = undefined;
      }
    });
    return result;
  }

  async setViewdBlocks(blockId: String) {
    return callPromiseWrapper("/api/block/viewed", { blockId: blockId });
  }

  async sentUndo(blockId: String) {
    return callPromiseWrapper("/api/block/sent/undo", { blockId: blockId });
  }

  async createBlock(documents: SelectedDocumentModel[]) {
    const data = [] as any[];
    for (const i in documents) {
      data.push({
        id: documents[i].id,
        base64url: documents[i].base64url,
      });
    }
    return await callPromiseWrapper("/api/block/create", data);
  }

  async devolveBlock(blockId: String) {
    return callPromiseWrapper("/api/block/devolve", { blockId: blockId });
  }

  async getSentBlocks(operatorId: string) {
    const result = (
      await callPromiseWrapper("/api/block/sent", { operatorId: operatorId })
    ).data as BlockModel[];
    result.forEach((block) => {
      block.date = new Date(block.date);
      block.documentsUrl = JSON.parse(block.documentsUrl as any);
      if (block.contabilizedDatetime) {
        block.contabilizedDatetime = new Date(block.contabilizedDatetime);
      } else {
        block.contabilizedDatetime = undefined;
      }
    });
    return result;
  }

  async getBlockToSolve(clientId: string) {
    const result = (
      await callPromiseWrapper("/api/block/tocategorize", {
        clientId: clientId,
      })
    ).data;
    if (result) {
      result.date = new Date();
      result.value = "";
      result.accountId = "";
      result.documentsUrl = JSON.parse(result.documentsUrl as any);
    }
    return result;
  }

  async saveBlock(block: BlockModel) {
    return callPromiseWrapper("/api/block/save", { block: block });
  }
}

export default BlockServicesImpl;
