/** @jsxImportSource @emotion/react */
import { useState } from "react";
import useLoadingTransitionExpanded from "../../../../Hooks/LoadingTransitionExpanded";
import {
  AccountingContact,
  emptyAccountingAcontact,
} from "../../../../Models/AccountingModel";
import { Client } from "../../../../Models/ClientModel";
import { OperatorProfile } from "../../../../Models/OperatorModel";
import LoadingTransition from "../../../LoadingTransition/View";
import { getAccountingContact } from "../../Presenter";
import FrameDrawer from "../FrameDrawer/View";
import ContactPanel from "./Components/ContactPanel/View";
import ProfileEditPanel from "./Components/ProfileEditPanel/View";
import ProfileOfficePanel from "./Components/ProfileOfficePanel/View";
// import ProfilePasswordPanel from "./Components/ProfilePasswordPanel/View";
import { useProfileDrawerStyle } from "./Style";

function ProfileDrawer({
  profileDrawerRef,
  operatorState,
  openProfileOfficeDialog,
  clientState,
}: {
  profileDrawerRef: React.MutableRefObject<() => void>;
  openProfileOfficeDialog: React.MutableRefObject<() => void>;
  operatorState: [
    OperatorProfile,
    React.Dispatch<React.SetStateAction<OperatorProfile>>
  ];
  clientState?: [Client, React.Dispatch<React.SetStateAction<Client>>];
}) {
  const style = useProfileDrawerStyle();

  const [acocuntingContact, setAccountingContact] = useState<AccountingContact>(
    emptyAccountingAcontact
  );

  const isClient = clientState !== undefined;

  const [transitionOff, expandedState, changeLoading] =
    useLoadingTransitionExpanded(
      acocuntingContact.accounting.name !== "",
      isClient ? () => getAccountingContact(setAccountingContact) : undefined
    );

  profileDrawerRef.current = () => expandedState[1](true);
  return (
    <FrameDrawer
      drawerOpened={expandedState[0]}
      onClose={() => expandedState[1](false)}
      scrollContent
    >
      <>
        <LoadingTransition transitionOff={transitionOff} window>
          {(_changeLoading) => {
            changeLoading.current = _changeLoading;
            return (
              <>
                <div css={style.wrapper}>
                  <ProfileEditPanel
                    operatorState={operatorState}
                    clientState={clientState}
                  />
                  {/* <ProfilePasswordPanel />
                  // Hidden: aguardando maturidade do projeto para habilitar essa funcionalidade */}
                  {operatorState[0].type === 0 ? (
                    <ProfileOfficePanel
                      closeDrawer={() => expandedState[1](false)}
                      openProfileOfficeDialog={openProfileOfficeDialog}
                      client={isClient}
                      name={
                        clientState && clientState[0].type === "juridicPerson"
                          ? clientState[0].person.name
                          : undefined
                      }
                    />
                  ) : undefined}
                  {/* <ProfilePaymentPanel />
                  // Hidden: aguardando maturidade do projeto para habilitar essa funcionalidade 
            <ProfileBillPanel /> */}
                  {isClient ? (
                    <ContactPanel acocuntingContact={acocuntingContact} />
                  ) : undefined}
                </div>
              </>
            );
          }}
        </LoadingTransition>
      </>
    </FrameDrawer>
  );
}

export default ProfileDrawer;
