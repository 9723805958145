import {
  KeyedDates,
  KeyedDatesGeneralModel,
} from "../Hooks/ShowAccountingsBlocks/Model";
import { monthSort } from "./Utils";

// Constants
export const hour24InMilliSeconds = 86400000;
export const min15InMilliSeconds = 900000;
export const waitOnFailureInMilliSeconds = 500;
export const days7inMiliSeconds = 604800000;

export const mapMonth = {
  "0": "Janeiro",
  "1": "Fevereiro",
  "2": "Março",
  "3": "Abril",
  "4": "Maio",
  "5": "Junho",
  "6": "Julho",
  "7": "Agosto",
  "8": "Setembro",
  "9": "Outubro",
  "10": "Novembro",
  "11": "Dezembro",
  Janeiro: "0",
  Fevereiro: "1",
  Março: "2",
  Abril: "3",
  Maio: "4",
  Junho: "5",
  Julho: "6",
  Agosto: "7",
  Setembro: "8",
  Outubro: "9",
  Novembro: "10",
  Dezembro: "11",
  Todos: "Todos",
} as { [key: string]: string };

export const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
].reverse();

export const years = ["2020", "2021", "2022", "2023", "2024", "2025"].reverse();

// Functions
export function randomDate() {
  const start = new Date();
  const end = new Date(new Date().valueOf() - hour24InMilliSeconds * 7);
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

export function minuteAndSecondFormat(duration: number) {
  // Minutes and seconds
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;
  var ret = "";
  ret += (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "");
  ret += secs;
  return ret;
}

export function hourAndMinutesDateFormat(duration: number) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;
  var ret = "";
  if (hrs > 0) {
    ret += (hrs < 10 ? "0" : "") + hrs + ":" + (mins < 10 ? "0" : "") + mins;
  } else {
    ret += (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "") + secs;
  }
  return ret;
}

export function dateIsValid(date: Date) {
  return date instanceof Date && !isNaN(date.valueOf());
}

export const dateFromMonthAndYear = (month: string, year: string) =>
  new Date((Number(mapMonth[month]) + 1).toString() + "/01/" + year);

export function organizeDates(dates: Date[], array: boolean) {
  // Garantindo que sempre haja o mês e ano atual para os frameSelects.
  dates.push(new Date());
  const dateSet = {} as { [year: string]: Set<string> };
  for (const date of dates) {
    const year = date.getFullYear().toString();
    if (!Object.keys(dateSet).includes(year)) {
      dateSet[year] = new Set();
    }
    dateSet[year].add(date.getMonth().toString());
  }

  const organizedDates = {} as { [year: string]: string[] };
  for (const year in dateSet) {
    organizedDates[year] = [];
    dateSet[year].forEach((month) => organizedDates[year].push(month));
    organizedDates[year].sort(monthSort);
    for (var i = 0; i < organizedDates[year].length; i++) {
      organizedDates[year][i] = mapMonth[organizedDates[year][i]];
    }
  }

  const keyedObjects = {} as KeyedDatesGeneralModel;
  for (const year in organizedDates) {
    if (!Object.keys(keyedObjects).includes(year)) {
      keyedObjects[year] = {};
    }
    for (const month of organizedDates[year]) {
      if (!Object.keys(keyedObjects[year]).includes(month)) {
        keyedObjects[year][month] = array ? [] : {};
      }
    }
  }
  return keyedObjects;
}

export function extractDates(
  list: KeyedDatesGeneralModel,
  setYear: React.Dispatch<React.SetStateAction<string>>,
  setMonth: React.Dispatch<React.SetStateAction<string>>,
  setDates: React.Dispatch<React.SetStateAction<KeyedDates>>
) {
  const selectDates = {} as { [year: string]: string[] };
  for (const year in list) {
    selectDates[year] = [];
    for (const month in list[year]) {
      selectDates[year].push(mapMonth[month]);
    }
    selectDates[year].sort(monthSort);
    for (var i = 0; i < selectDates[year].length; i++) {
      selectDates[year][i] = mapMonth[selectDates[year][i]];
    }
  }
  const year = Object.keys(selectDates).sort(monthSort)[0];
  var currentYear: string;
  setYear((_year) => {
    if (Object.keys(selectDates).includes(_year)) {
      currentYear = _year;
      return _year;
    } else {
      currentYear = year;
      return year;
    }
  });
  setMonth((_month) => {
    if (selectDates[currentYear].find((month) => _month === month)) {
      return _month;
    } else {
      return selectDates[currentYear][0];
    }
  });
  setDates(selectDates);
}
