/** @jsxImportSource @emotion/react */
import { BlockModel } from "../../../../../../Models/BlockModel";
import { years } from "../../../../../../Utils/Dates";
import { useFrameDrawerStyle } from "../../../../../Frame/Components/FrameDrawer/Style";
import FrameSelect from "../../../../../FrameSelect/View";
import TextEntry from "../../../../../TextEntry/View";
import TextEntryMasked from "../../../../../TextEntryMasked/View";
import ViewBlockAutocomplete from "../ViewBlockAutocomplete/View";
import { useEditBlockEntriesPresenter } from "./Presenter";
import { useEditBlockEntriesStyle } from "./Style";

function EditBlockEntries({
  modal = false,
  editBlock,
  setDisabledButton,
  onSaveRef,
  show = true,
  noInteraction = false,
  categorize = false,
}: {
  modal?: boolean;
  editBlock: BlockModel;
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveRef: React.MutableRefObject<() => void>;
  show?: boolean;
  noInteraction?: boolean;
  categorize?: boolean;
}) {
  const style = useEditBlockEntriesStyle();
  const frameDrawerStyle = useFrameDrawerStyle();
  const presenter = useEditBlockEntriesPresenter(
    style.time,
    modal,
    categorize,
    show,
    editBlock,
    setDisabledButton,
    onSaveRef
  );

  return (
    <>
      <div css={frameDrawerStyle.subtitle}>Data de pagamento</div>
      <div css={style.dateRow}>
        <TextEntry
          ccss={style.dayTextEntry}
          placeholder="dd"
          initialValue={presenter.day}
          onChange={presenter.setDay}
          setTextRef={presenter.setDayRef}
        //   patternMask="00"
          hiddenClear
        />
        <FrameSelect
          color="darker"
          ccss={[
            style.monthSelect,
            noInteraction ? style.noInteractions : undefined,
          ]}
          value={presenter.month}
          onSelect={presenter.setMonth}
          options={presenter.getMonths()}
          showLenghtOptions={modal ? 7 : undefined}
          heightOfFixContent={presenter.heightOfFixContent}
        />
        <FrameSelect
          color="darker"
          ccss={[
            style.yearSelect,
            noInteraction ? style.noInteractions : undefined,
          ]}
          value={presenter.year}
          options={years}
          onSelect={presenter.setYear}
          showLenghtOptions={modal ? 5 : undefined}
          heightOfFixContent={presenter.heightOfFixContent}
        />
        <div
          css={[
            style.mask,
            style.buttonMask,
            style.monthMask,
            presenter.hiddenMask ? style.maskHidden : undefined,
            presenter.hiddenTransition ? style.maskTransiton : undefined,
          ]}
        />
        <div
          css={[
            style.mask,
            style.buttonMask,
            style.yearMask,
            presenter.hiddenMask ? style.maskHidden : undefined,
            presenter.hiddenTransition ? style.maskTransiton : undefined,
          ]}
        />
      </div>
      <div css={frameDrawerStyle.subtitle}>Conta</div>
      <div css={style.stack}>
        <div
          ref={presenter.mountRef}
          css={[
            style.mask,
            style.accountMask,
            presenter.hiddenMask ? style.maskHidden : undefined,
            presenter.hiddenTransition ? style.maskTransiton : undefined,
          ]}
        />
        <ViewBlockAutocomplete
          value={presenter.selectedAccountId}
          setValue={presenter.setSelectedAccountId}
          month={presenter.month}
          year={presenter.year}
        />
      </div>
      <div css={frameDrawerStyle.subtitle}>Valor</div>
      <TextEntry
        placeholder="R$"
        initialValue={presenter.value}
        onChange={presenter.setValue}
        setTextRef={presenter.setValueRef}
        hiddenClear
        isCurrency
      />
    </>
  );
}

export default EditBlockEntries;
